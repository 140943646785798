import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
};

const counterSlice = createSlice({
  name: 'triage',
  initialState,
  reducers: {
  },
})

export const { } = counterSlice.actions
export default counterSlice.reducer