import React from 'react';
import { MdDownload } from "react-icons/md";


const DownloadExcelButton: React.FC<{ data: any[] }> = ({ data }) => {
    const downloadExcel = () => {
        // Convert data to CSV format
        const csvContent = "data:text/csv;charset=utf-8," +
            Object.keys(data[0]).join(",") + "\n" +
            data.map(item => Object.values(item).map(value => typeof value === 'string' ? `"${value}"` : value).join(",")).join("\n");

        // Create a download link
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();
    };

    return (
        // <button >Download Excel</button>
        <MdDownload onClick={downloadExcel} />
    );
};

export default DownloadExcelButton;
